import {Component} from '@angular/core'
import {FOOTER_CONST} from '../../consts/footer.const'
import {MatToolbar} from '@angular/material/toolbar'
import {environment} from '../../../../environments/environment'

@Component({
    selector: 'app-footer',
    standalone: true,
    imports: [
        MatToolbar
    ],
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss'
})
export class FooterComponent {
    appVersion: string = environment.version
    protected readonly footer = FOOTER_CONST
}
