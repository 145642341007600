import {Component, EventEmitter, inject, input, InputSignal, OnInit, Output} from '@angular/core'
import {MatButton, MatIconButton} from '@angular/material/button'
import {MatIcon} from '@angular/material/icon'
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu'
import {MatToolbar, MatToolbarRow} from '@angular/material/toolbar'
import {RouterLink, RouterLinkActive} from '@angular/router'
import {AuthService} from '../../../auth/auth.service'
import {NgOptimizedImage} from '@angular/common'


@Component({
  selector: 'app-navbar',
  standalone: true,
    imports: [
        MatButton,
        MatIcon,
        MatIconButton,
        MatMenu,
        MatMenuItem,
        MatToolbar,
        MatToolbarRow,
        RouterLink,
        RouterLinkActive,
        MatMenuTrigger,
        NgOptimizedImage
    ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent implements OnInit{
    public authService: AuthService = inject(AuthService)

    showButtons: {label: string; link: string; permission: any, show: boolean}[] = []
    userName: string
    useWithAuth: InputSignal<boolean> = input<boolean>(true)
    @Output() onHamburgerClicked: EventEmitter<void> = new EventEmitter<void>()

    constructor() {

    }

    ngOnInit() {
        if (!this.useWithAuth()) {
            return
        }

        this.userName = this.authService.user?.account?.firstname && this.authService.user?.account?.surname
            ? this.authService.user?.account?.firstname + ' ' + this.authService.user?.account?.surname
            : this.authService.user?.account?.email

        this.showButtons = [
            {label: 'Clients', link: 'clients', permission: null, show: true},
            {label: 'Members', link: 'members', permission: null, show: true},
            {label: 'Commission Rates', link: 'commission-rates', permission: null, show: true},
            {label: 'Vendors', link: 'vendors', permission: null, show: this.authService.isAllowedForAdminAndOakmoreMembers()},
            {
                label: 'Invoices',
                link: 'invoices',
                permission: null,
                show: this.authService.isAllowedForAdminAndOakmoreMembers()
            },
            {
                label: 'Brokers',
                link: 'brokers',
                permission: null,
                show: this.authService.isAllowedForAdminAndOakmoreMembers()
            },
            {
                label: 'Agencies',
                link: 'agencies',
                permission: null,
                show: this.authService.isAllowedForAdminAndOakmoreMembers()
            },
            // {
            //     label: 'Account Managers',
            //     link: 'account-managers',
            //     permission: null,
            //     show: this.authService.isAllowedForAdminAndOakmoreMembers()
            // },
        ]
    }

    hasPermission(permission: any): boolean {
        return this.authService.isSuperAdmin() || this.authService.hasPermissions(permission)
    }

    toggleMenu() {
        this.onHamburgerClicked.emit()
    }
}
