
import {EnvTypes} from './env.types'

export const environment: EnvTypes = {
  production: false,
  version: '0.99.17',
  API_URL: 'https://members-back-end-dev.onrender.com/',
  BUILD_TIME: '2024-12-12T14:12:31.154Z',
  FOOTER_TITLE: 'Development'

}
