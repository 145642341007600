import {Injectable} from '@angular/core'
import {MomentDateAdapter} from '@angular/material-moment-adapter'
import {Moment} from 'moment'
import * as moment from 'moment'

@Injectable()
export class CustomDateAdapter extends MomentDateAdapter {
    override createDate(year: number, month: number, date: number): Moment {
        return moment.utc({year, month, date}).startOf('day')
    }
}
