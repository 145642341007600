import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpInterceptorFn,
    HttpRequest
} from '@angular/common/http'
import {environment} from '../../../../environments/environment'


export const apiEndpointInterceptor: HttpInterceptorFn = (req, next) => {
    if (req.url.includes('/assets/')) {
        return next(req)
    }
    req = req.clone({url: environment.API_URL + req.url})
    return next(req)
}
