import {
    HttpErrorResponse,
    HttpInterceptorFn,
} from '@angular/common/http'
import {inject, Injector} from '@angular/core'
import {Router} from '@angular/router'
import {throwError} from 'rxjs'
import {catchError} from 'rxjs/operators'
import {AuthService} from '../../../auth/auth.service'

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
    const injector: Injector = inject(Injector)
    const auth: AuthService = inject(AuthService)

    return next(req).pipe(
        catchError((err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    auth.setToken(null)
                    const router = injector.get(Router)
                    router.navigate(['/auth'], {replaceUrl: true})
                    console.error('Unauthorized request:', err)
                } else {
                    console.error('HTTP error:', err)
                }
            } else {
                console.error('An error occurred:', err)
            }
            return throwError(() => err)
        })
    )
}

